<template>
    <div>
        <v-row v-if="type === 'note' || !this.contentId">
            <v-col cols="2">
                <v-btn
                    @click.stop="showNewInputDialog()">
                    <v-icon left>mdi-plus-circle</v-icon> New {{type}}
                </v-btn>
            </v-col>

            <v-col v-if="type === 'note' && inactiveExist"
                cols="2">
                <v-switch class="mt-0 pt-1"
                    v-model="showInactive"
                    :label="`Show Inactive`"
                ></v-switch>
            </v-col>
        </v-row>

        <v-data-iterator
            :items="items"
            hide-default-footer
            sort-by="updatedAt"
            sort-desc
            no-data-text="">

            <template v-slot:item="props">
                <v-row v-if="isEnabled(props.item) || showInactive">
                    <v-col style="margin-top:.5em;">

                        <InventoryDetails :type="type"
                            :id="props.item.id"
                            :content="props.item"></InventoryDetails>

                    </v-col>
                </v-row>
            </template>

        </v-data-iterator>
    </div>
</template>


<script>
import { mapActions } from 'vuex'
import { rest } from '../_helpers'

const InventoryDetails = () => import(
    /* webpackChunkName: "inventory-details" */ '@/components/InventoryDetails.vue'
)

export default {
    props: {
        id: Number,
        contentId: Number,
        type: String,
        refType: Number,
    },

    data() {
        return {
            items: [],
            showInactive: false,
            inactiveExist: false,
        }
    },

    components: {
        InventoryDetails,
    },

    methods: {
        ...mapActions({
            showInputDialog: 'input/showDialog',
            setInputDialog: 'input/setInputDialog',
        }),

        isEnabled(item) {
            let enabled = true

            if (this.type === 'note' && !item.enabled) {
                this.inactiveExist = true
                enabled = item.enabled
            }

            return enabled
        },

        showNewInputDialog() {
            const data = { refId: this.id, refType: this.refType ? this.refType : 0 }

            this.setInputDialog({
                type: this.type,
                confirm: false,
                title: this.type.charAt(0).toUpperCase() + this.type.slice(1),
                data,
                modified: (idx, item) => {
                    this.items.push(item)

                    this.showInputDialog(false)
                },
            })
            this.showInputDialog(true)
        },

        getRows() {
            let payload

            if (this.type === 'note') {
                payload = {
                    limit: 15,
                    where: {
                        refType: this.refType ? this.refType : 0,
                        refId: this.id,
                    },
                    order: [
                        ['updatedAt', 'DESC'],
                    ],
                }
            } else if (this.contentId) {
                this.showInactive = true
                payload = {
                    where: {
                        id: this.contentId,
                    },
                }
            }

            if (payload) {
                rest.getRows(this.type, payload)
                    .then(items => {
                        this.items = items
                    })
            }
        },
    },

    created() {
        this.getRows()
    },
}
</script>
