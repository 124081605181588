<template>
    <div>
        <v-row>
            <v-col cols="2">
                <v-btn
                    @click.stop="showNewInputDialog()">
                    <v-icon left>mdi-plus-circle</v-icon> {{ $t('New Note') }}
                </v-btn>
            </v-col>
        </v-row>

        <v-row style="margin-top:.5em;"
                v-for="(note , idx) in notes"
                :key="'note_'+idx">
            <v-col>
                <Note :id="note.id"
                    :type="'note'"
                    :content="note"></Note>
            </v-col>
        </v-row>
    </div>
</template>


<script>
import { mapActions } from 'vuex'
import { rest } from '../_helpers'

const Note = () => import(
    /* webpackChunkName: "inventory-details" */ '@/components/InventoryDetails.vue'
)

export default {
    props: {
        id: Number,
        note: {
            default() {
                return '-'
            },
            type: String,
        },
    },

    data() {
        return {
            notes: [],
        }
    },

    components: {
        Note,
    },

    methods: {
        ...mapActions({
            showInputDialog: 'input/showDialog',
            setInputDialog: 'input/setInputDialog',
        }),

        getNotes() {
            const payload = {
                where: {
                    refType: 4,
                    refId: this.id,
                },
                order: [
                    ['enabled', 'DESC'],
                    ['updatedAt', 'DESC'],
                ],
            }

            rest.getRows('note', payload)
                .then(notes => {
                    this.notes = notes
                })
        },

        showNewInputDialog() {
            const data = { refId: this.id, refType: 4 }

            this.setInputDialog({
                type: 'note',
                confirm: false,
                title: this.$t('Note'),
                data,
                modified: (idx, item) => {
                    this.notes.push(item)

                    this.showInputDialog(false)
                },
            })
            this.showInputDialog(true)
        },
    },

    created() {
        this.getNotes()
    },
}
</script>
