import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.type === 'note' || !this.contentId)?_c(VRow,[_c(VCol,{attrs:{"cols":"2"}},[_c(VBtn,{on:{"click":function($event){$event.stopPropagation();return _vm.showNewInputDialog()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus-circle")]),_vm._v(" New "+_vm._s(_vm.type)+" ")],1)],1),(_vm.type === 'note' && _vm.inactiveExist)?_c(VCol,{attrs:{"cols":"2"}},[_c(VSwitch,{staticClass:"mt-0 pt-1",attrs:{"label":"Show Inactive"},model:{value:(_vm.showInactive),callback:function ($$v) {_vm.showInactive=$$v},expression:"showInactive"}})],1):_vm._e()],1):_vm._e(),_c(VDataIterator,{attrs:{"items":_vm.items,"hide-default-footer":"","sort-by":"updatedAt","sort-desc":"","no-data-text":""},scopedSlots:_vm._u([{key:"item",fn:function(props){return [(_vm.isEnabled(props.item) || _vm.showInactive)?_c(VRow,[_c(VCol,{staticStyle:{"margin-top":".5em"}},[_c('InventoryDetails',{attrs:{"type":_vm.type,"id":props.item.id,"content":props.item}})],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }