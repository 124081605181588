<template>
    <v-container style="max-width:unset;">
        <v-row>
            <v-col>
                <v-card>
                    <v-card-text>Vendor: {{ !!vendor ? vendor : '-' }}</v-card-text>
                </v-card>
            </v-col>

            <v-col cols="3">
                <v-card>
                    <v-card-text>Price: {{ !!price ? price : '-' }} €</v-card-text>
                </v-card>
            </v-col>
            <v-col cols="3">
                <v-card>
                    <v-card-text>Ordered At: {{ !!orderDate ? date : '-' }}</v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
const moment = require('moment-timezone')

export default {
    props: {
        id: Number,
        price: Number,
        vendor: String,
        orderDate: String,
    },

    data() {
        return {
        }
    },

    computed: {
        date() {
            return moment(this.orderDate).format('YYYY-MM-DD')
        },
    },
}
</script>
