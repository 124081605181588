import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticStyle:{"max-width":"unset"}},[_c(VRow,[_c(VCol,[_c(VCard,[_c(VCardText,[_vm._v("Login info: "+_vm._s(!!_vm.loginInfo ? _vm.loginInfo : '-'))])],1)],1)],1),_c(VRow,[_c(VCol,[_c(VCard,[_c(VCardText,[_vm._v("PIN 1: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.pin1))])])],1)],1),_c(VCol,[_c(VCard,[_c(VCardText,[_vm._v("PIN 2: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.pin2))])])],1)],1),_c(VCol,[_c(VCard,[_c(VCardText,[_vm._v("PUK 1: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.puk1))])])],1)],1),_c(VCol,[_c(VCard,[_c(VCardText,[_vm._v("PUK 2: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.puk2))])])],1)],1)],1),_c(VRow,[_c(VCol,[_c(VCard,[_c(VCardText,[_vm._v("Credit: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.credit))])])],1)],1),_c(VCol,[_c(VCard,[_c(VCardText,[_vm._v("Credited At: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.creditedAt))])])],1)],1),_c(VCol,[_c(VCard,[_c(VCardText,[_vm._v("Currency: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.currency))])])],1)],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"2"}},[_c(VBtn,{on:{"click":function($event){$event.stopPropagation();return _vm.showNewInputDialog()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus-circle")]),_vm._v(" "+_vm._s(_vm.$t('New Note'))+" ")],1)],1)],1),_vm._l((_vm.notes),function(note,idx){return _c(VRow,{key:idx},[_c(VCol,[_c('Note',{attrs:{"id":note.id,"content":note,"type":'note',"refType":3}})],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }