import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length)?_c(VMenu,{attrs:{"offset-x":"","offset-y":"","open-on-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icon ? _vm.icon : 'mdi-link')+" ")])],1)]}}],null,false,1993783835)},[_c(VList,[_c(VListItemGroup,_vm._l((_vm.items),function(item,index){return _c('div',{key:index},[(item.link)?_c(VListItem,{on:{"click":function($event){return item.link()}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1):_vm._e(),(item.href)?_c(VListItem,{attrs:{"to":item.href()}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1):_vm._e()],1)}),0)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }