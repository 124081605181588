<template>
    <v-menu v-if="items.length"
        offset-x
        offset-y
        open-on-click>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                text
                v-bind="attrs"
                v-on="on">
                <v-icon>
                    {{ icon ? icon : 'mdi-link' }}
                </v-icon>
            </v-btn>
        </template>

        <v-list>
            <v-list-item-group>
                <div v-for="(item, index) in items"
                    :key="index">
                    <v-list-item v-if="item.link"
                        @click="item.link()">
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.title }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="item.href"
                        :to="item.href()">
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.title }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
            </v-list-item-group>
        </v-list>
    </v-menu>
</template>


<script>
// const moment = require('moment-timezone')

export default {
    props: {
        id: Number,
        icon: String,
        getList: Function,
    },

    data() {
        return {
            items: [],
        }
    },

    created() {
        this.getList(this.id)
            .then(result => {
                this.items = result
            })
    },

    watch: {
        id(val) {
            this.getList(val)
                .then(result => {
                    this.items = result
                })
        },
    },
}
</script>
