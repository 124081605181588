<template>
    <v-container style="max-width:unset;">
        <v-row>
            <v-col>
                <v-card>
                    <v-card-text>Login info: {{ !!loginInfo ? loginInfo : '-' }}</v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card>
                    <v-card-text>PIN 1: <span class="font-weight-bold">{{ pin1 }}</span></v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-card-text>PIN 2: <span class="font-weight-bold">{{ pin2 }}</span></v-card-text>
                </v-card>
            </v-col>

            <v-col>
                <v-card>
                    <v-card-text>PUK 1: <span class="font-weight-bold">{{ puk1 }}</span></v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-card-text>PUK 2: <span class="font-weight-bold">{{ puk2 }}</span></v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card>
                    <v-card-text>Credit: <span class="font-weight-bold">{{ credit }}</span></v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-card-text>Credited At: <span class="font-weight-bold">{{ creditedAt }}</span></v-card-text>
                </v-card>
            </v-col>

            <v-col>
                <v-card>
                    <v-card-text>Currency: <span class="font-weight-bold">{{ currency }}</span></v-card-text>
                </v-card>
            </v-col>

        </v-row>

        <v-row>
            <v-col cols="2">
                <v-btn
                    @click.stop="showNewInputDialog()">
                    <v-icon left>mdi-plus-circle</v-icon> {{ $t('New Note') }}
                </v-btn>
            </v-col>
        </v-row>

        <v-row v-for="(note , idx) in notes"
                    :key="idx">
            <v-col>
                <Note :id="note.id"
                      :content="note"
                      :type="'note'"
                      :refType="3"
                      ></Note>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import { mapActions } from 'vuex'
import { rest } from '../_helpers'

const Note = () => import(
    /* webpackChunkName: "inventory-details" */ '@/components/InventoryDetails.vue'
)

export default {
    props: {
        id: Number,
        loginInfo: String,
        credit: Number,
        creditedAt: Date,
        currency: String,
        pin1: String,
        pin2: String,
        puk1: String,
        puk2: String,
    },

    data() {
        return {
            notes: [],
        }
    },

    components: {
        Note,
    },

    methods: {
        ...mapActions({
            showInputDialog: 'input/showDialog',
            setInputDialog: 'input/setInputDialog',
        }),

        getNotes() {
            const payload = {
                where: {
                    refType: 3,
                    refId: this.id,
                },
                order: [
                    ['enabled', 'DESC'],
                    ['updatedAt', 'DESC'],
                ],
            }

            rest.getRows('note', payload)
                .then(notes => {
                    this.notes = notes
                })
        },

        showNewInputDialog() {
            const data = { refId: this.id, refType: 3 }

            this.setInputDialog({
                type: 'note',
                confirm: false,
                title: this.$t('Note'),
                data,
                modified: (idx, item) => {
                    this.notes.push(item)

                    this.showInputDialog(false)
                },
            })
            this.showInputDialog(true)
        },
    },

    created() {
        this.getNotes()
    },
}
</script>
