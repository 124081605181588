<template>
    <div v-if="item">
        <v-btn text v-if="item.link"
            @click="item.link()">
            {{ item.title }}
        </v-btn>
        <v-btn text v-if="item.href"
            :to="item.href()">
            {{ item.title }}
        </v-btn>
    </div>
</template>


<script>
// const moment = require('moment-timezone')

export default {
    props: {
        id: Number,
        getItem: Function,
    },

    components: {
    },

    data() {
        return {
            item: null,
        }
    },

    created() {
        this.getItem(this.id)
            .then(result => {
                this.item = result
            })
    },

    watch: {
        id(val) {
            this.getItem(val)
                .then(result => {
                    if (val === this.id) {
                        this.item = result
                    }
                })
        },
    },
}
</script>
