<template>
    <div>
        <div :id="`dataTableCard-${id}`"
            class="dataTableCard"
            v-resize="onResize"
            :ref="`dataTableCard-${id}`">

            <v-card flat>
                <v-card-title id="table-title">
                    <span class="mr-6">{{ title }}</span>

                    <!--<v-icon :color="activeSwitch ? 'grey' : 'error'">mdi-sim-off-outline</v-icon>
                    <v-switch class="ml-3 mr-2 px-0"
                        inset
                        v-model="activeSwitch"
                    ></v-switch>
                    <v-icon :color="activeSwitch ? 'success' : 'grey'">mdi-sim-outline</v-icon>-->

                    <v-spacer></v-spacer>

                    <v-row>
                        <v-card flat width="200">
                            <v-select v-if="selectItems"
                                v-model="filterItem"
                                return-object
                                class="mr-8"
                                :items="selectItems"
                                hide-details
                                :label="selectName"
                            ></v-select>
                        </v-card>

                        <v-text-field
                            v-model="globalSearch"
                            append-icon="mdi-magnify"
                            :label="$t('search')"
                            @input="setColumnSearch(null, arguments[0])"
                            hide-details
                            clearable
                        ></v-text-field>

                        <v-card flat>
                            <v-card-subtitle class="pb-0 pt-0">
                                {{ $t('columns') }}
                            </v-card-subtitle>
                            <v-card-actions class="my-0 py-0">
                                <v-checkbox
                                    class="ml-3 mr-2 mt-1"
                                    v-model="columnSearch"
                                >
                                </v-checkbox>
                            </v-card-actions>
                        </v-card>

                        <v-card flat>
                            <v-card-subtitle class="pb-0 pt-0">
                                {{ $t('reset') }}
                            </v-card-subtitle>
                            <v-card-actions class="my-0 py-0">
                                <v-btn icon
                                    @click="resetFilter">
                                    <v-icon :color="globalSearch || Object.keys(columnSearchFilter).length > 0 ? 'error' : ''">mdi-filter-variant-remove</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-row>
                </v-card-title>

                <v-data-table :headers="clearedTableHeaders"
                    :hide-default-header="true"
                    mobile-breakpoint="0"
                    :items="items"
                    :expanded.sync="expanded"
                    item-key="id"
                    class="fixed-header"
                    must-sort
                    :sort-by="sortBy"
                    :sort-desc="sortDesc"
                    :ref="`dataTable-${id}`"
                    :no-data-text="$t('no_data')"
                    :custom-filter="searchFilter"
                    :footer-props="{
                        itemsPerPageOptions: [25,50,100,-1],
                        itemsPerPageText: '',
                    }"
                    :options.sync="page.pagination"
                    :items-per-page.sync="page.pagination.itemsPerPage"
                    :search="search">

                    <template
                    v-slot:header="{ props: { headers: propsHeaders } }"
                    >
                    <thead class="v-data-table-header" style="vertical-align: middle;">
                        <tr>
                            <th
                                v-for="header in propsHeaders"
                                :key="header.text"
                                :style="{ width: header.width ? header.width : 'unset' }"
                                :class="['column sortable text-left', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
                                >
                                <div :style="{display: columnSearch ? 'block' : 'none'}"
                                    style="height: 50px;">
                                    <v-select v-if="header.sortable && header.selectables"
                                        :items="getSelectMenuItems(header.selectables)"
                                        outlined
                                        dense
                                        :value="columnSearchValue"
                                        @change="setColumnSearch(header.searchValue ? header.searchValue : header.value, arguments[0])"
                                    ></v-select>

                                    <v-text-field v-else-if="header.sortable && header.type !== 'bool'"
                                        :value="columnSearchValue"
                                        :style="{ minWidth: header.width ? header.width : 'unset' }"
                                        background-color="white"
                                        :label="header.sortable ? '' : ''"
                                        :outlined="header.sortable"
                                        dense
                                        flat
                                        hide-details
                                        class="column-search"
                                        @input="setColumnSearch(header.searchValue ? header.searchValue : header.value, arguments[0])"
                                    >
                                    <!-- <template v-slot:prepend-inner="">
                                        <v-icon x-small>mdi-magnify</v-icon>
                                    </template> -->
                                    </v-text-field>

                                    <v-card v-else
                                        :width="header.width">
                                    </v-card>
                                </div>
                                <div class="pb-2">
                                    <span @click="header.sortable ? changeSort(header.value) : () => {}">{{ header.text }}</span>
                                    <v-icon v-if="sortBy === header.value" small>{{ sortBy === header.value && !sortDesc ? 'mdi-arrow-up' : 'mdi-arrow-down' }}</v-icon>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    </template>

                    <template v-slot:item="props">
                        <tr>
                            <td v-for="(header, idx) in clearedTableHeaders"
                                :key="idx"
                                class="px-3">

                                <v-edit-dialog v-if="header.editable"
                                    :return-value.sync="props.item[header.value]"
                                    large
                                    @save="save(props.item)"
                                    >
                                    <v-flex v-if="header.type == 'bool'">
                                            <v-icon
                                                :color="header.level && header.level(props.item) ? 'error' : ''">
                                                {{ props.item[header.value] ? 'mdi-check' : 'mdi-close' }}
                                            </v-icon>
                                        </v-flex>
                                    <div v-else>{{ ( header.repr ? header.repr( props.item, getFormat(props.item, header.value, header.type) ) : getFormat(props.item, header.value, header.type) ) }}</div>
                                    <template v-slot:input>
                                        <v-checkbox v-if="header.type == 'bool'"
                                            v-model="props.item[header.value]"
                                            :label="`${header.text}`"
                                            ></v-checkbox>

                                        <v-text-field v-else
                                            v-model="props.item[header.value]"
                                            :rules="[max25chars]"
                                            label="Edit"
                                            single-line
                                            autofocus
                                            ></v-text-field>
                                    </template>
                                </v-edit-dialog>

                                <v-flex v-else-if="header.value == 'note'">

                                    <v-badge
                                        offset-x="11"
                                        offset-y="13"
                                        overlap
                                        dot
                                        :color="`${props.item.invType && props.item[props.item.invType.name]
                                            ? 'primary lighten-3'
                                            : (props.item.notes && props.item.notes.length
                                                ? 'success lighten-1'
                                                : '')}`"
                                        :value="props.item.notes && props.item.notes.length>=1 || (props.item.invType && props.item[props.item.invType.name]) ? 1 : 0">

                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon text
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    v-if="true/*props.item.notes.length>=1 || props.item[props.item.type]*/"
                                                    @click="expandRow(props.item)"
                                                    class="px-0 mx-0 table-action-btn">

                                                        <v-icon>{{ (expandedItems[props.item.id] ? 'mdi-chevron-up' : 'mdi-chevron-down') }}</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ getExpandTooltip(props.item.invType && props.item[props.item.invType.name], props.item.notes && props.item.notes.length) }}</span>
                                        </v-tooltip>
                                    </v-badge>
                                </v-flex>

                                <v-flex v-else-if="header.value == 'edit'">
                                    <v-btn icon
                                           @click="editItem(props.item)"
                                           class="px-0 mx-0 table-action-btn">
                                        <v-icon>mdi-square-edit-outline</v-icon>
                                    </v-btn>
                                </v-flex>

                                <v-flex v-else-if="header.type == 'menu' && header.menuItems">
                                    <v-menu v-if="header.menuItems(props.item).length"
                                        offset-x
                                        left
                                        open-on-hover>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                                >
                                                <v-icon>
                                                    {{ header.linkIcon ? header.linkIcon : 'mdi-link' }}
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item-group>
                                                <div v-for="(mitem, index) in header.menuItems(props.item).reverse()"
                                                        :key="index">
                                                    <v-list-item v-if="mitem.href"
                                                            :href="mitem.href()">
                                                        <v-list-item-content>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on: tooltip }">
                                                                    <v-list-item-title
                                                                    v-on="{ ...tooltip }">{{ mitem.title }}</v-list-item-title>
                                                                </template>
                                                                {{ mitem.from }} - {{ mitem.to }}
                                                            </v-tooltip>

                                                        </v-list-item-content>
                                                    </v-list-item>

                                                    <v-list-item v-else-if="mitem.link"
                                                            @click="mitem.link()">
                                                        <v-list-item-content>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on: tooltip }">
                                                                    <v-list-item-title
                                                                    v-on="{ ...tooltip }">{{ mitem.title }}</v-list-item-title>
                                                                </template>
                                                                {{ mitem.from }} - {{ mitem.to }}
                                                            </v-tooltip>

                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </div>
                                            </v-list-item-group>
                                        </v-list>
                                    </v-menu>
                                </v-flex>

                                <v-flex v-else-if="header.type == 'menu'">
                                    <TableMenuField
                                        :icon="header.linkIcon"
                                        :id="props.item.id"
                                        :getList="header.menu"
                                        />
                                </v-flex>

                                <v-flex v-else-if="header.type == 'link' && header.isList && header.linkIf(props.item)">
                                    <div v-for="(link, idx) in header.link(props.item)"
                                        :key="idx">
                                        <v-btn
                                            :icon="!link.name"
                                            :text="!!link.name"
                                            class="px-3 mx-0 table-action-btn"
                                            :href="link.href">
                                            <div v-if="link.name">
                                                {{ link.name }}
                                            </div>
                                            <v-icon v-else>
                                                {{ header.linkIcon ? header.linkIcon : 'mdi-link' }}
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </v-flex>

                                <v-flex v-else-if="header.type == 'link'">
                                    <div v-if="header.href && (!header.linkIf || header.linkIf(props.item))">
                                        <v-btn
                                            :icon="!header.linkName"
                                            :text="!!header.linkName"
                                            class="px-3 mx-0 table-action-btn"
                                            :href="header.href(props.item)"
                                            :target="header.linkBlank ? '_blank' : ''">
                                            <div v-if="header.linkName">
                                                <v-btn v-if="header.linkCopy" icon small @click.prevent="copyContent(`link-${header.linkName(props.item).replace(/\s+/g, '_')}`)">
                                                    <v-list-item-icon class="mr-0">
                                                        <v-icon size="small">mdi-content-copy</v-icon>
                                                    </v-list-item-icon>
                                                </v-btn>
                                                <span :id="`link-${header.linkName(props.item).replace(/\s+/g, '_')}`" :style="{ paddingLeft: header.linkCopy ? '10px' : ''}">{{ header.linkName(props.item) }}</span>
                                            </div>
                                            <v-icon v-else>
                                                {{ header.linkIcon ? header.linkIcon : 'mdi-link' }}
                                            </v-icon>
                                        </v-btn>
                                    </div>

                                    <div v-else-if="!header.linkIf || header.linkIf(props.item)">
                                        <v-btn
                                            :icon="!header.linkName"
                                            :text="!!header.linkName"
                                            class="px-3 mx-0 table-action-btn"
                                            :href="header.href ? header.href(props.item) : null"
                                            @click="header.link(props.item)">
                                            <div v-if="header.linkName">
                                                {{ header.linkName(props.item) }}
                                            </div>
                                            <v-icon v-else>
                                                {{ header.linkIcon ? header.linkIcon : 'mdi-link' }}
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </v-flex>

                                <v-flex v-else-if="header.type == 'bool'">
                                    <v-icon
                                        :color="header.level && header.level(props.item) ? 'error' : ''">
                                        {{ props.item[header.value] ? 'mdi-check' : 'mdi-close' }}
                                    </v-icon>
                                </v-flex>

                                <v-flex v-else-if="header.type == 'string_split'">
                                    <div v-for="(row, idx) in getStringSplit(props.item[header.value])"
                                         :key="'string_'+idx">
                                        {{ row }}
                                    </div>
                                </v-flex>

                                <v-flex v-else-if="header.type == 'mail_split'">
                                    <div v-for="(row, idx) in getStringSplit(props.item[header.value])"
                                         :key="'mail_'+idx">
                                        <v-btn text
                                               @click="mailAddress(row)">{{ row }}
                                        </v-btn>
                                    </div>
                                </v-flex>

                                <v-flex v-else-if="header.type == 'tel_split'">
                                    <div v-for="(row, idx) in getStringSplit(props.item[header.value])"
                                         :key="'tel_'+idx">
                                        <v-btn text
                                               @click="telNumber(row)">{{ row }}</v-btn>
                                    </div>
                                </v-flex>

                                 <v-flex v-else-if="header.link">
                                    <TablePromiseField
                                        :id="props.item.id"
                                        :getItem="header.link" />
                                 </v-flex>

                                 <div v-else>
                                    <v-flex v-if="header.reprType === 'icon'"
                                        :class="(header.level != null ? failureIcon(header.level(props.item)) : '')"
                                        :style="{
                                                textAlign: (header.contentAlign ? header.contentAlign : 'left'),
                                            }">
                                            <v-icon :color="header.reprColor(getFormat(props.item, header.value, header.type))">{{ header.repr( props.item, getFormat(props.item, header.value, header.type) ) }}</v-icon>
                                    </v-flex>
                                    <v-flex v-else
                                        :class="(header.level != null ? failureIcon(header.level(props.item)) : '')"
                                        :style="{
                                                textAlign: (header.contentAlign ? header.contentAlign : 'left'),
                                            }"
                                        v-html="( header.repr ? header.repr( props.item, getFormat(props.item, header.value, header.type) ) : getFormat(props.item, header.value, header.type) )">
                                    </v-flex>
                                </div>
                            </td>
                        </tr>
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                            <v-card flat
                                    color="grey"
                                    lighten-6>
                                <v-card-text>
                                    <ExpandSim v-if="type === 'sim'"
                                        :loginInfo="item.loginInfo"
                                        :id="item.id"
                                        :noteIds="item.notes"
                                        :credit="getString(item.credit)"
                                        :creditedAt="getString(item.dateCredited)"
                                        :currency="getString(item.currency)"
                                        :pin1="getString(item.pin1)"
                                        :pin2="getString(item.pin2)"
                                        :puk1="getString(item.puk1)"
                                        :puk2="getString(item.puk2)" />

                                    <ExpandComputer v-else-if="type === 'computer'"
                                        :id="item.id"
                                        :noteIds="item.notes" />

                                    <ExpandInventory v-else-if="type === 'inventory'"
                                        :id="item.id"
                                        :orderDate="item.dateOrdered"
                                        :price="item.price"
                                        :vendor="item.vendor" />

                                    <ExpandRow v-if="type === 'inventory' && Object.hasOwnProperty.call(item, item.invType.name)"
                                        :id="item.id"
                                        :contentId="item[item.invType.name] ? item[item.invType.name].id : null"
                                        :type="item.invType.name" />

                                    <v-card-title v-if="type === 'inventory' && item.notes && item.notes.length">
                                        {{ $t('notes') }}
                                    </v-card-title>

                                    <ExpandRow v-if="type === 'inventory'"
                                        :id="item.id"
                                        :type="'note'" />

                                </v-card-text>
                            </v-card>
                        </td>
                    </template>
                </v-data-table>
            </v-card>
        </div>
    </div>
</template>


<script>
import { mapState, mapActions } from 'vuex'
import ExpandSim from '@/components/ExpandSim.vue'
import ExpandComputer from '@/components/ExpandComputer.vue'
import ExpandInventory from '@/components/ExpandInventory.vue'
import ExpandRow from '@/components/ExpandRow.vue'
import TableMenuField from '@/components/TableMenuField.vue'
import TablePromiseField from '@/components/TablePromiseField.vue'
import { rest } from '../_helpers'

const moment = require('moment-timezone')


export default {
    props: {
        headers: Array,
        data: {
            default() {
                return []
            },
            type: Array,
        },
        type: String,
        title: String,
        expandable: Boolean,
        editable: Boolean,
        pagination: {
            default() {
                return { itemsPerPage: 25 }
            },
            type: Object,
        },
        preValidate: {
            default() {
                return Promise.resolve(true)
            },
            type: Function,
        },
        selectedItem: Object,
        customFilter: Function,
        filterItems: {
            default() {
                return null
            },
            type: Array,
        },
        filterName: String,
        filterSelected: Object,
    },

    components: {
        ExpandSim,
        ExpandComputer,
        ExpandRow,
        ExpandInventory,
        TableMenuField,
        TablePromiseField,
    },

    data() {
        return {
            items: [],
            filterText: null,
            selectItems: this.filterItems,
            selectName: this.filterName,
            rowsPerPage: 25,
            max25chars: v => v.length <= 25 || 'Input too long!',

            editedItem: {},
            editedIndex: -1,
            expanded: [],
            expandedItems: {},
            search: '',
            tableHeaders: this.headers.slice(0),
            tablePagination: this.pagination,
            columnSearch: false,
            globalSearch: '',
            columnSearchFilter: {},
            sortBy: null,
            sortDesc: false,
            id: crypto.randomUUID(),
            activeSwitch: true,
            columnSearchValue: null,
        }
    },

    computed: {
        ...mapState({
            page: 'page',
        }),

        filterItem: {
            get() {
                return this.filterText
            },
            set(val) {
                this.$emit('filter-select', val)
                this.filterText = val
            },
        },

        clearedTableHeaders() {
            const clearedHeaders = []
            this.tableHeaders.forEach(header => {
                if (!header.hidden) {
                    clearedHeaders.push(header)
                }
            })
            return clearedHeaders
        },
    },

    methods: {
        ...mapActions({
            showInputDialog: 'input/showDialog',
            setInputDialog: 'input/setInputDialog',
            setSortOptions: 'page/setSortOptions',
            showSnackbar: 'page/showSnackbar',
            setSnackbar: 'page/setSnackbar',
        }),

        getSelectMenuItems(items) {
            return [{ value: null, text: '' }, ...items.map((item, idx) => ({
                value: idx,
                text: item,
            }))]
        },

        copyContent(copyData) {
            const copyText = document.getElementById(copyData)
            navigator.clipboard.writeText(copyText.textContent)

            this.setSnackbar({
                type: 'success',
                text: `${this.$t('Link copied!')}`,
            })
            this.showSnackbar(true)
        },

        searchFilter(value, search, item) {
            if (this.customFilter) {
                return this.customFilter(value, search, item)
            }

            let columnSearchFound = true
            const searchColumns = Object.keys(this.columnSearchFilter)
            if (searchColumns.length > 0) {
                const found = searchColumns.filter(key => {
                    let itemValue
                    let comparator
                    const searchValue = this.columnSearchFilter[key].toString().toLowerCase()
                    const keySplit = key.split('.')
                    const cleanKey = keySplit[0]
                    const subKey = keySplit[1]

                    if (cleanKey.startsWith('$')) {
                        itemValue = item[cleanKey.slice(1)]
                        comparator = val => {
                            if (Array.isArray(val)) {
                                return val.some(itemVal => (subKey === undefined
                                    ? itemVal.toString().toLowerCase() === searchValue
                                    : (itemVal[subKey] && itemVal[subKey].toString().toLowerCase() === searchValue)
                                ))
                            }
                            return subKey === undefined
                                ? val.toString().toLowerCase() === searchValue
                                : (val[subKey] && val[subKey].toString().toLowerCase() === searchValue)
                        }
                    } else {
                        itemValue = item[cleanKey]
                        comparator = val => {
                            if (Array.isArray(val)) {
                                return val.some(itemVal => (subKey === undefined
                                    ? itemVal.toString().toLowerCase().includes(searchValue)
                                    : (itemVal[subKey] && itemVal[subKey].toString().toLowerCase().includes(searchValue))
                                ))
                            }
                            return subKey === undefined
                                ? val.toString().toLowerCase().includes(searchValue)
                                : (val[subKey] && val[subKey].toString().toLowerCase().includes(searchValue))
                        }
                    }

                    return itemValue !== null
                        && itemValue !== undefined
                        && comparator(itemValue)
                })
                columnSearchFound = found.length === searchColumns.length
            }

            const globalSearchPhrase = this.globalSearch.toString().toLowerCase()

            const globalFound = value ? Object.keys(item).filter(key => item[key] !== null
                    && item[key] !== undefined
                    && item[key].toString().toLowerCase().includes(globalSearchPhrase.toString().toLowerCase())) : false
            return globalFound.length > 0 && columnSearchFound
        },

        changeSort(column) {
            this.sortDesc = this.sortBy === column ? !this.sortDesc : false
            this.sortBy = column
        },

        resetFilter() {
            this.globalSearch = ''
            this.columnSearchFilter = {}
            this.columnSearchValue = this.columnSearchValue === '' ? null : ''
        },

        parseFilter(searchText) {
            let searchObject = {}
            const regex = /\[\[\s*(?<column>[^\]]+):\s*(?<value>[^\]]+)\s*\]\]/gm
            const match = regex.exec(searchText)
            if (match.groups) {
                searchObject = match.groups
            }
            return searchObject
        },

        setColumnSearch(column, inputValue) {
            if (column !== null) {
                if (inputValue !== null) {
                    this.columnSearchFilter[column] = `${inputValue}`
                } else {
                    delete this.columnSearchFilter[column]
                }
            } else {
                this.globalSearch = inputValue
            }

            this.search = `${this.globalSearch} ${Object.keys(this.columnSearchFilter).map(key => `[[ ${key}: '${this.columnSearchFilter[key]}' ]]`).join(' ')}`
        },

        save(item) {
            rest.setItem(this.type, item)
        },

        expandRow(item) {
            const propId = item.id
            if (Object.hasOwnProperty.call(this.expandedItems, propId)) {
                delete this.expandedItems[propId]
            } else {
                this.expandedItems[propId] = item
            }

            this.expanded = Object.values(this.expandedItems)
        },

        failureIcon(level) {
            let levelIcon = ''

            if (level > 0) {
                // levelIcon = ' <i aria-hidden="true" style="font-size:1em;" class="v-icon notranslate mdi mdi-circle theme--light '
                if (level === 2) {
                    levelIcon += 'red--text font-weight-bold'
                } else if (level === 1) {
                    levelIcon += 'orange--text font-weight-bold'
                }
                // levelIcon += '"></i>'
            }

            return levelIcon
        },

        getString(value) {
            return value !== null ? value : ''
        },

        getExpandTooltip(hasData, hasNote) {
            let tooltip = this.$t('no_additional_data')
            if (hasData && hasNote) {
                tooltip = this.$t('additional_and_notes')
            } else {
                if (hasData) {
                    tooltip = this.$t('additional_exist')
                }
                if (hasNote) {
                    tooltip = this.$t('notes_exist')
                }
            }

            return tooltip
        },

        getFormat(row, col, type) {
            let item
            let result
            if (row && col) {
                item = ({ ...row })

                col.split('.').forEach(i => {
                    if (item) {
                        item = item[i]
                    }
                })
            }

            result = item

            if (type === 'number') {
                result = this.getNumberString(item)
            } else if (type === 'date') {
                result = this.getDateString(item)
            } else if (type === 'datetime') {
                result = item ? moment(item).tz('Europe/Berlin').format('YYYY-MM-DD HH:mm') : ''
            }

            return result !== undefined ? result : ''
        },

        mailAddress(address) {
            window.location.href = `mailto:${address}`
        },
        telNumber(number) {
            window.location.href = `tel:${number}`
        },

        getStringSplit(item) {
            let items = []

            if (item) {
                items = item.split(',')
            }

            return items
        },

        getDateString(date) {
            return date ? moment(date).tz('Europe/Berlin').format('YYYY-MM-DD') : ''
        },

        getNumberString(num) {
            return new Intl.NumberFormat('de-DE', {}).format(num)
        },

        setTableHeight() {
            const tabsEl = document.getElementsByClassName('v-tabs')
            const tabsHeight = tabsEl.length > 0 ? tabsEl[0].offsetHeight : 10

            const listContainerHeight = document.getElementById('main-content').getElementsByClassName('v-main__wrap')[0].clientHeight
                - document.getElementById('table-title').clientHeight
                - document.getElementsByClassName('v-data-footer')[0].offsetHeight
                - tabsHeight
            /* console.log(
                document.getElementById('main-content').getElementsByClassName('v-main__wrap')[0].clientHeight,
                document.getElementById('table-title').clientHeight,
                document.getElementsByClassName('v-data-footer')[0].offsetHeight,
                tabsHeight,
                listContainerHeight,
            ) */
            document.getElementById(`dataTableCard-${this.id}`).getElementsByClassName('v-data-table__wrapper')[0].setAttribute('style', `max-height: ${Math.floor(listContainerHeight)}px !important`)
            return listContainerHeight
        },

        onResize() {
            document.getElementById(`dataTableCard-${this.id}`).getElementsByClassName('v-data-table__wrapper')[0].setAttribute('style', 'max-height: 0px !important')
            this.setTableHeight()
        },

        editItem(item) {
            if (item) {
                const input = {
                    id: this.items.indexOf(item),
                    visible: true,
                    type: this.type,
                    title: this.title,
                    data: ({ ...item }),
                    modified: (rowId, rowData) => {
                        if (rowId > -1) {
                            this.headers.forEach(header => {
                                if (header.reset) {
                                    header.reset(rowData)
                                }
                            })
                            this.$set(this.items, rowId, rowData)
                        }

                        this.showInputDialog(false)
                    },
                    preValidate: this.preValidate,
                }

                this.setInputDialog(input)
            }
        },

        setExpandable(newHeaders) {
            const user = JSON.parse(localStorage.getItem(process.env.VUE_APP_STORAGE_KEY))

            if (user && user.user.role <= 2) {
                if (this.expandable) {
                    newHeaders.push({
                        text: this.$t('info'),
                        align: 'right',
                        sortable: false,
                        value: 'note',
                        width: '3em',
                    })
                }
            }

            if (user && user.user.role <= 1) {
                if (this.editable) {
                    newHeaders.push({
                        text: this.$t('edit'),
                        align: 'right',
                        sortable: false,
                        value: 'edit',
                        width: '3em',
                    })
                }
            }
        },
    },

    created() {
        const newHeaders = this.headers.slice(0)
        this.setExpandable(newHeaders)
        this.tableHeaders = newHeaders

        if (!this.tablePagination.itemsPerPage) {
            this.tablePagination.itemsPerPage = this.rowsPerPage
        }

        this.setSortOptions(this.tablePagination)

        // this.editItem(this.selectedItem)
        if (this.selectedItem) {
            this.columnSearchFilter = { ...this.selectedItem }
            this.search = `${this.globalSearch} ${Object.keys(this.selectedItem).map(key => `[[ ${key}: '${this.selectedItem[key]}' ]]`).join(' ')}`
        }
    },

    mounted() {
        setTimeout(this.setTableHeight, 50)
    },

    watch: {
        selectedItem(val) {
            // this.editItem(val)

            if (val) {
                this.columnSearchFilter = { id: val.id }
                this.search = `${this.globalSearch} ${Object.keys(val).map(key => `[[ ${key}: '${val[key]}' ]]`).join(' ')}`
            }
        },

        data(val) {
            this.items = val
        },

        filterItems(val) {
            this.selectItems = val
            this.selectName = this.filterName
            this.filterText = this.filterSelected
        },

        filterSelected(val) {
            this.selectItems = this.filterItems
            this.selectName = this.filterName
            this.filterText = val
        },

        headers(val) {
            const newHeaders = val.slice(0)
            this.setExpandable(newHeaders)
            this.tableHeaders = newHeaders
        },
    },
}
</script>


<style lang="stylus">
    .v-data-footer
        width 100%
        position fixed
        bottom 0
        clear both
        background-color white

    fixed-table-header($height) {
      .v-data-table__wrapper {
        overflow-y: auto !important

        if typeof($height) == 'object' {
          for $key, $value in $height {
            @media $key {
              max-height: $value !important
            }
          }
        } else {
          max-height: $height !important
        }
      }

      .theme--light th {
        background-color: #ff0000
      }
      .theme--dark th {
        background-color: #4cff00
      }

      th {
        position: sticky
        top: 0
        z-index: 1
      }

      tr.v-datatable__progress {
        th {
          top: 55px
          height: 1px
        }
      }
    }

    .dataTableCard
        height 100%

    .fixed-header
        fixed-table-header(80vh)

    .v-datatable__actions
        position fixed
        bottom 0
        width 100%

    .v-data-table-header th
        background-color white

    .column-search   .v-label
        font-size 9px

    .column-search .v-text-field__slot input
        font-size 12px

    .v-application--is-ltr .v-input--selection-controls__input
        margin-right 0
</style>
